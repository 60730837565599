export const logo = "https://resoures-educem.s3.us-west-2.amazonaws.com/Logos/Avantthya/img/avantthya_blanco.png"
export const logo_rojo = "https://resoures-educem.s3.us-west-2.amazonaws.com/Logos/Avantthya/700350cb.png"
//export const API_URL = "http://192.168.1.130:8080/api/";
// export const API_URL='https://avantthyatest.mecap.mx/api/';
 export const API_URL='https://avantthya.mecap.mx/api/';

//export const API_URL = "http://ec2-54-201-56-34.us-west-2.compute.amazonaws.com:8082/api/"
 
export const MAIN_COLOR = "#db1d1f"
export const SECONDARY_COLOR = "#001230"

//imagenes
const API_URL_CLOUDFRONT = 'https://d2khtgq7ihsmbk.cloudfront.net/';


export const logo_avantthya = `${API_URL_CLOUDFRONT}Logos/Avantthya/img/avantthya_blanco.png`;
export const entidades_regulatorias = `${API_URL_CLOUDFRONT}Logos/Avantthya/img/icono_entidades_regulatorias.png`;

//.pdf footer
const RESOURCES_FOOTER = 'https://resoures-educem.s3.us-west-2.amazonaws.com/Avantthya/Pages/';

export const aviso_privacidad = RESOURCES_FOOTER + "aviso-privacidad.pdf";
export const comisiones = RESOURCES_FOOTER + "comisiones-noviembre-2022.pdf";
export const comite_remuneracion = RESOURCES_FOOTER + "comiteremuneracion.pdf";
export const despachos_cobranza = RESOURCES_FOOTER + "dcobranza-2.pdf";

