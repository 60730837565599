
import { useState } from "react";
import { getComponent } from "../../services/ComponentServices";
import Visualizador from "./Visualizador";

function ComiteRemuneracion() {
    

    return (
        <Visualizador type={8} ></Visualizador>
    );
}

export default ComiteRemuneracion;