import Visualizador from "./Visualizador";



function DespachoCobranza() {


    return (
        <Visualizador type={10} ></Visualizador>
    );
}

export default DespachoCobranza;