import {
    Box,
    DialogActions,
    DialogContent,
    Snackbar,
    Alert,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    CircularProgress,
    Fab,
    StepLabel
} from '@mui/material';
import React, {useEffect, useState} from 'react'
import ReactInputMask from 'react-input-mask';
import {postNewLead} from '../../services/CreditService';
import {MAIN_COLOR, SECONDARY_COLOR} from '../../utils/constants';

export default function RequestForm(props: any) {
    const [boolAlert, setboolAlert] = useState(false)

    const [form, setform] = useState({
        name: '',
        title: props.product?.product?.title,
        email: '',
        phone: '',
        idCredit: props.product?.id,
        amount: props.product?.cantidad,
        idPlazo: props.periodicity.id,
        meet: '',
        max: 0,
        min: 0,
        plazos: []
    })
    const [errorCell, seterrorCell] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setloading] = useState(false)
    const [boolLimite, setboolLimite] = useState(false)
    const [alertText, setalertText] = useState("")
    const [selectedPlazo, setselectedPlazo] = useState("")

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    function isValidEmail(email: any) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmail = (event: any) => {
        if (!isValidEmail(event.target.value)) {
            setError(true);
        } else {
            setform({...form, email: event.target.value});
            setError(false);
        }
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setboolAlert(false);
    };

    const recalcularResultados = (p: any) => {
        setselectedPlazo(p.id)
        setform({...form, idPlazo: p.idrangetime})
    }

    const enviarFormulario = async () => {
        setloading(true)
        if (form.name !== "" && form.phone !== "") {

            if (Object.keys(props.meet).length !== 0) {
                const timeString = props?.meet.time.toLowerCase().replace('.', ''); // Convert to lowercase and remove any period (.)

                const [hour, minute] = timeString.split(':').map((part: any) => parseInt(part, 10)); // Extract hour and minute values

                let [hours, indicator] = hour.toString().split(' '); // Split the hour and AM/PM indicator

                hours = parseInt(hours, 10); // Convert the hour to a number

                if (indicator === 'p.m.' && hours !== 12) {
                    hours += 12; // Adjust the hour for PM times (except for 12 PM)
                } else if (indicator === 'a.m.' && hours === 12) {
                    hours = 0; // Adjust the hour for 12 AM (midnight)
                }

                const [month, day, year] = new Date(props?.meet.day).toLocaleDateString().split('/').map((part: any) => parseInt(part, 10)); // Extract month, day, and year values

                const combinedString = `${year}-${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
                form.meet = combinedString || "";
            }

            if (form.amount >= form.min && form.amount <= form.max) {
                const response = await postNewLead(form);
                if (response.status === 200) {
                    setloading(false)
                    props.closeWithResponse(true);
                    props.close()
                } else {
                    setloading(false)
                }
            } else {
                setalertText("¡La cantidad supera el mínimo o el máximo para este producto!")
                setboolAlert(true)
                setloading(false)
            }

        } else {
            setloading(false)
            setboolAlert(true)
            setalertText("¡Por favor llenar los campos!")
        }

    }

    const onChangeMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        setform({...form, phone: e.target.value})
    }

    const validarCantidad = (cant: number) => {
        const txt = document.getElementById("txtCantidad")
        if (cant > form.max || cant < form.min) {
            if (txt)
                txt.style.color = MAIN_COLOR

            setform({
                ...form,
                amount: cant
            })
            setboolLimite(true)
        } else {
            if (txt)
                txt.style.color = SECONDARY_COLOR
            setboolLimite(false)
            setform({
                ...form,
                amount: cant
            })
        }
    }

    const filtrarProductos = async (name: any) => {
        const aux = props.products.filter((p: any) => p.product.title === name)

        setform({
            ...form,
            amount: aux[0].min,
            title: aux[0].product.title,
            min: aux[0].min,
            max: aux[0].max,
            idCredit: aux[0].id,
            plazos: aux[0].rangetimecreditList
        })
    }

    useEffect(() => {
        setform({
            name: '',
            title: props.product?.product?.title,
            email: '',
            phone: '',
            idCredit: props.product?.id,
            amount: props.product?.cantidad,
            idPlazo: props.periodicity.id,
            meet: '',
            max: props.product?.max,
            min: props.product?.min,
            plazos: props.product.rangetimecreditList
        })
    }, [props])


    useEffect(() => {
        const VerifyTel = async () => {
            if (form.phone !== '') {
                let forSendTel = form.phone!.replaceAll('_', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '');
                setform({...form, phone: forSendTel});
            }
            if (form.phone!.length === 10) {
                seterrorCell(false);
            }
            if (form.phone!.length > 0 && form.phone!.length < 10) {
                seterrorCell(true);
            }

        }
        VerifyTel();
    }, [form.phone]);

    return (
        <Box sx={{borderRadius: 2, border: "1px solid black", p: 3}}>
            <Typography variant={"h3"} id="responsive-dialog-title">
                {/*<Fab variant="circular" size='small' sx={{ position: 'absolute', right: 20, background: `${MAIN_COLOR}`, color: 'white', ':hover': { color: 'red' } }} onClick={() => props.close()}><CloseIcon></CloseIcon></Fab>*/}
                <Typography variant="h4">Solicita tu <b style={{color: '#010130'}}>Crédito</b></Typography>
                <Typography variant="h6" color="red">Uno de nuestros ejecutivos con gusto te contactará</Typography>

                {
                    Object.keys(props?.meet).length !== 0 &&
                    <Typography>Fecha y hora agendado para cita: <b>{new Date(props.meet.day).toLocaleDateString()}</b>
                        <br/> Hora: <b>{props.meet.time}</b></Typography>
                }
            </Typography>
            <DialogContent>
                <Box display="flex" flexDirection="column">
                    <TextField InputLabelProps={{style: {color: '#010130'}}} required id="standard-basic" label="Nombre"
                               type={"text"} onChange={e => setform({...form, name: e.target.value})} variant="standard"
                               error/>
                    <TextField InputLabelProps={{style: {color: '#010130'}}} id="standard-basic"
                               label="Correo electrónico" onChange={e => handleEmail(e)} variant="standard"
                               type={"email"} error/>
                    {
                        error && <label style={{color: 'red'}}>El correo es inválido. Vuelve a intentarlo.</label>
                    }
                    <ReactInputMask
                        mask='9999999999'
                        value={form.phone!}
                        onChange={onChangeMask}
                        maskChar={null}
                        required
                        maskPlaceholder={null}
                        style={{marginTop: 5}}
                    >
                        <TextField fullWidth variant="standard" size="small" required label="Número de teléfono"
                                   InputLabelProps={{style: {color: '#010130'}}}
                                   sx={{
                                       color: 'black',
                                       p: 0,
                                       mt: 0,
                                       mr: 1,
                                       input: {fontWeight: 600, textAlign: 'start', pt: 0},
                                       '& .MuiInput-underline:after': {borderBottomColor: 'black'},
                                   }}
                                   value={form.phone!}
                                   error={errorCell}
                                   helperText={errorCell === false ? null : "El número debe contener 10 dígitos"}
                        />
                    </ReactInputMask>
                    <Typography variant="button" fontWeight="bold" mt={2}>Cuentas con:</Typography>
                    <FormControl variant="standard" sx={{m: 1}}>
                        <InputLabel id="demo-simple-select-standard-label">Selecciona el tipo de crédito</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Age"
                            error
                            value={form.title}
                            onChange={e => filtrarProductos(e.target.value)}
                        >
                            {
                                props.products.map((p: any) => (
                                    <MenuItem key={p.id} value={p.product.title}
                                              sx={{fontWeight: 'bold'}}>{p.product.title}</MenuItem>
                                ))
                            }

                        </Select>
                    </FormControl>

                    <FormControl sx={{m: 1, mt: 3}}>
                        <InputLabel htmlFor="outlined-adornment-amount" sx={{color: 'black'}}>Introduce una
                            cantidad</InputLabel>
                        <OutlinedInput
                            size='medium'
                            error
                            id="txtCantidad"
                            type='number'
                            value={form.amount}
                            sx={{fontWeight: 'bold', fontSize: 20}}
                            onChange={e => validarCantidad(+e.target.value)}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Introduce una cantidad"
                        />
                        {
                            boolLimite && <StepLabel sx={{margin: 1}} color={MAIN_COLOR}> Monto
                                mínimo: <b>{formatter.format(form.min)}</b> <br/> *Monto
                                máximo: <b>{formatter.format(form.max)}</b></StepLabel>
                        }
                    </FormControl>
                    <Box display="flex" flexDirection="row" justifyContent="space-evenly" width='100%'>
                        {
                            form.plazos && form.plazos
                                .sort((a: any, b: any) => a.rangetime.value - b.rangetime.value)
                                .map((r: any) => (
                                    <Fab
                                        key={r.id}
                                        size="large"
                                        sx={{
                                            background: selectedPlazo === r.id ? `${SECONDARY_COLOR}` : `${MAIN_COLOR}`,
                                            color: 'white',
                                            fontSize: 19,
                                            fontWeight: 'bold',
                                            ":hover": {color: 'red'}
                                        }}
                                        onClick={() => recalcularResultados(r)}>
                                        {r.rangetime.value}
                                    </Fab>
                                ))
                        }
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions onClick={() => enviarFormulario()} sx={{
                background: '#ff1719',
                color: 'white',
                cursor: 'pointer',
                justifyContent: 'center',
                ":hover": {background: '#f55252'}
            }}>
                <Typography variant="h4" fontWeight="bold">Enviar</Typography>
                {
                    loading && <CircularProgress sx={{marginLeft: 3}}></CircularProgress>
                }
            </DialogActions>

            <Snackbar open={boolAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} variant="filled" severity="warning" sx={{width: '100%'}}>
                    {alertText}
                </Alert>
            </Snackbar>
        </Box>
    )
}

