import { Box, Container, Divider, Grid, Grow, Paper, Skeleton, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import ProductButton from '../components/general/ProductButton'
import 'react-multi-carousel/lib/styles.css';
import { getAllCredits, getCredit } from '../services/CreditService';
import { ProductContext } from '../contexts/GeneralContexts';
import CardsSkeleton from '../components/general/CardsSkeleton';

export default function Products() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4
        },
        smallDesktop: {
            breakpoint: { max: 750, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const [products, setproducts] = useState<any>([])
    const [prodContext, setprodContext] = useContext(ProductContext)

    const goToProduct = async (prod: any) => {
        if (prod.product.producttype.id === 3) {
            window.open(prod.product.link, "blank")
        } else {
            const response = await getCredit(prod.id);
            if (response.status === 200)
                setprodContext(response.data)
        }
    }

    const getProducts = async () => {
        const response = await getAllCredits()

        if (response.status == 200) {
            const inversionProduct = response.data.findIndex((obj: any) => obj.product.producttype.id === 3);

            if (inversionProduct !== -1) {
                let arr = []
                arr = [
                    response.data[inversionProduct],
                    ...response.data.slice(0, inversionProduct),
                    ...response.data.slice(inversionProduct + 1)
                ]
                setproducts(arr)
            }
        }

    }

    useEffect(() => {
        getProducts()
    }, [])


    return (
        <Container sx={{ marginTop: 5, marginBottom: 5 }}>
            <Typography variant="h4" color="#ff1719" fontWeight={600}>Servicios y Productos Financieros</Typography>
            <Divider></Divider>

            <Carousel
                responsive={responsive}
                showDots={true}
                autoPlaySpeed={1000}
                transitionDuration={500}
                keyBoardControl={true}
                infinite={true}
                removeArrowOnDeviceType="mobile">
                {
                    products.length !== 0 ? products.map((p: any) => (
                        <Paper  key={p.id} elevation={0} sx={{ ":hover": { transform: 'scale(1.2)', transition: '0.4s' } }}>
                            <div  style={{ margin: 20 }}>
                                <img src={p.product.icon1} style={{ cursor: 'pointer', width: '100%' }} onClick={() => goToProduct(p)} />
                            </div>
                        </Paper>
                    )) : <CardsSkeleton></CardsSkeleton>
                }
            </Carousel>

        </Container>
    )
}
