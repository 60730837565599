import "../../assets/styles/ParagrapBlog.css";
const ParagraphText: React.FC<{ text?: string, preview?:boolean,intro?:boolean, }> = ({ text,preview,intro }) => {
  const highlightWords = (text: string): React.ReactNode => {
    const fragments: React.ReactNode[] = [];
    const regexBold = /\*\*(.*?)\*\*/g;
    const regexLink = /\[(.*?)\]/g;
    let lastIndex = 0;
    let matchBold;

    while ((matchBold = regexBold.exec(text)) !== null) {
      const normalText = text.substring(lastIndex, matchBold.index);
      const highlightedText = matchBold[1];

      fragments.push(normalText);
      fragments.push(
        <span key={matchBold.index} style={{ fontWeight: 900 }}>
          {highlightedText}
        </span>
      );

      lastIndex = matchBold.index + matchBold[0].length;
    }

    if(text){
      if (lastIndex < text.length) {
        const remainingText = text.substring(lastIndex);
        fragments.push(remainingText);
      }
    }
    

    const finalFragments: React.ReactNode[] = [];

    fragments.forEach((fragment, i) => {
      if (typeof fragment === 'string') {
        const matchLink = regexLink.exec(fragment);
        if (matchLink !== null) {
          const normalText = fragment.substring(0, matchLink.index);
          const linkText = matchLink[1];
          finalFragments.push(normalText);
          finalFragments.push(
            <a key={i} href={linkText} target={'_blank'} rel="noopener noreferrer" >
              {linkText}
            </a>
          );
          const remainingText = fragment.substring(matchLink.index + matchLink[0].length);
          if (remainingText.length > 0) {
            finalFragments.push(remainingText);
          }
          regexLink.lastIndex = 0; // Reinicializamos el índice de regexLink
        } else {
          finalFragments.push(fragment);
        }
      } else {
        finalFragments.push(fragment);
      }
    });

    return finalFragments;
  };

  return <p className={preview ? "blog-text-preview" : intro ? "blog-text-intro" : "blog-text-normal"}>{highlightWords(text!)}</p>;
};

export default ParagraphText;



