import "../../assets/styles/ParagraphCarouselStyle.css"

const ParagraphCarrusel: React.FC<{ 
   paragraph?: string,
   paragraphSub?: string,
   paragraphDes?: string,
   title?: null | boolean, 
   subtitle?:null|boolean,
   description?:null|boolean }> = ({ paragraph,paragraphSub,paragraphDes,title,subtitle,description }) => {
  const highlightWords = (text: string): React.ReactNode[] => {
    const fragments: React.ReactNode[] = [];
    const regex = /\*\*(.*?)\*\*/g;
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const normalText = text.substring(lastIndex, match.index);
      const highlightedText = match[1];
      fragments.push(normalText);
      fragments.push(
        <strong key={match.index} style={{ color: "#db1d1f", fontWeight: 600 }}>
          {highlightedText}
        </strong>
      );
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      const remainingText = text.substring(lastIndex);
      fragments.push(remainingText);
    }
    return fragments;
  };

  return(
    <>
    {paragraph ? 
    <p className="carousel-title">{highlightWords(paragraph)}</p>
    : null }
   { paragraphSub? 
    <p className="carousel-sub"  >{highlightWords(paragraphSub)}</p> : null
    }
    {paragraphDes ? 
    <p className="carousel-des" >{highlightWords(paragraphDes)}</p> : null
    }
    
    </>
  ) 
};

export default ParagraphCarrusel;