import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Container, Fab, Fade, Grid, Skeleton, Toolbar, useMediaQuery } from '@mui/material';
import { getCredit, getRankCredits } from '../../services/CreditService';
import { MAIN_COLOR, SECONDARY_COLOR } from '../../utils/constants';
import { ProductContext } from '../../contexts/GeneralContexts';
import '../../assets/styles/MediaQueryCarousel.css'
import ParagraphCarrusel from './ParagraphCarrusel';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


export default function CarouselBanner() {

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const [credits, setcredits] = useState([])
  const [prodContext, setprodContext] = useContext(ProductContext)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const obtenerCreditos = async () => {
    const response = await getRankCredits()
    if (response.status == 200)
      setcredits(response.data)
  }


  const goToProduct = async (prod: any) => {

    if (prod.product.producttype.id === 3) {
      window.open(prod.product.link, "blank")
    } else {
      const response = await getCredit(prod.id);

      if (response.status === 200)
        setprodContext(response.data)
    }

  }

  useEffect(() => {
    obtenerCreditos()
  }, [])

  return (
    <>
      <Fade in={true}>
        <Box sx={{ width: '100%' }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={6000}
          >
            {credits.length !== 0 ? credits.map((c: any, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <div key={index} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <img
                      className='carousel-img'
                      src={`${c.product.image1}`}
                      alt={`${c.product ? c.product.title! : 'producto avanthya' + index}`}
                      aria-hidden="true"
                    />
                    <Container
                      maxWidth="lg"
                      sx={{
                        position: 'absolute', height: '100%', display: 'flex', flexDirection: 'column',
                        pl: { xs: 4, sm: 5, md: 8, lg: 4,xl:0 },
                        justifyContent: 'center', textAlign: 'start'
                      }} key={index} >
                      <Grid container justifyContent={'flex-start'} alignItems={'flex-start'} flexDirection={'column'}>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mt: 0 }}>
                          <ParagraphCarrusel paragraph={c.product.title!} />
                          <ParagraphCarrusel paragraphSub={c.product.subtitle!} />
                          <ParagraphCarrusel paragraphDes={c.product.description!} />
                          {/*  <Typography color={SECONDARY_COLOR} sx={{ fontWeight: 600, fontSize: { xs: '24px', md: '45px' } }}  > {c.product.title} </Typography>  */}
                          {/* <Typography color={MAIN_COLOR} sx={{ fontSize: { xs: '18px', md: '24px', l: '26px', xl: '26px' } }}> {c.product.subtitle}</Typography>  */}
                          {/* <Box width={350}>
                        <Typography variant='h6' sx={{ fontSize: { xs: '14px', md: '20px', l: '24px', xl: '26px' } }} > {c.product.description}</Typography>
                      </Box> */}
                          <Button
                            onClick={() => goToProduct(c)}
                            size={ xs||sm ? 'small' : 'medium'}
                            variant="contained"
                            sx={{
                              borderRadius: "22px",
                              color: 'white',
                              bgcolor: `${SECONDARY_COLOR}`,
                              textTransform: 'none',
                              whiteSpace: "normal",
                              fontSize: { xs: '11px', sm: '13px', md: '14px', lg: '16px', xl: '18px' },
                              mr: 0,mt:{xs:0,sm:1},
                              pl: 3, pr: 3,pt:0.2,pb:0.2,
                              cursor: 'pointer',
                              transition: 'all 0.2s ease-in-out', '&:hover': { transform: 'scale(1.1)', bgcolor: "white",color:`${SECONDARY_COLOR}` },
                            }}
                          >
                          {
                            c.product.button? c.product.button :
                            c.product.producttype.id === 3 ? '¡Comienza a invertir!' : '¡Solicítalo ahora!'
                          }
                            

                          </Button>
                          {/*   <Grid sx={{mt:{xs:0,md:1}}}>
                            <Fab
                            size='small'
                              sx={{
                                color: 'white',
                                bgcolor: `${SECONDARY_COLOR}`,
                                textTransform: 'none',
                                letterSpacing: { sm: 'none', md: '.05rem' },
                                fontSize:{xs:'8px',sm:'10px'},
                                pl: 3,
                                pr: 3,
                                mr: 1,
                                '&:hover': { color: `${SECONDARY_COLOR}`, transform: 'scale(1.1)' },
                                transition: '0.2s',
                              }}
                              variant="extended"
                              onClick={() => goToProduct(c)}
                            >
                              {c.product.producttype.id === 3 ? '¡Comienza a invertir!' : '¡Solicítalo ahora!'}
                            </Fab>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                ) : null}
              </div>
            )) : <Skeleton variant="rectangular" animation="wave" width="100%" height={300}></Skeleton>}
          </AutoPlaySwipeableViews>
        </Box>
      </Fade>
    </>
  )
}
