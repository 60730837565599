import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getComponent } from '../../services/ComponentServices';

const Notification = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [avisoImage, setavisoImage] = useState("");

    const obtenerAviso = async () => {
        const response = await getComponent(12, 2);
        if (response.status === 200)
            setavisoImage(response.data[0].image);
    }

    useEffect(() => {
        obtenerAviso();
        setShowNotification(true);
    }, []);

    const hideNotification = () => {
        setShowNotification(false);
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: xs || sm ? '25%' : '50%',
        left: xs || sm ? '10%' : '50%',
        transform: xs || sm ? 'translate(-4%, -5%)' : 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    };

    return (
        <>
            {showNotification &&  avisoImage !== '' ? (
                <Modal
                    open={showNotification}
                    onClose={hideNotification}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <img
                            loading="lazy"
                            src={avisoImage}
                            alt="Aviso no te dejes engañar"
                            style={{
                                borderRadius: '16px',
                                position: 'relative',
                                maxWidth: '100%',
                                maxHeight: 'auto',
                            }}
                        />
                        <IconButton
                            aria-label="tooltip button"
                            onClick={hideNotification}
                            sx={{ margin: 0, p: 0, position: 'relative', right: '35px', top: '10px', color: '#E80000' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Modal>
            ) : null}
        </>
    );
};

export default Notification;
