import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid/Grid'
import Paper from '@mui/material/Paper'
import React, { useEffect, useState } from 'react'
import handsIcon from '../static/icons/icono_manos.png'
import regulatedIcon from '../static/icons/icono2.png'
import assessmentIcon from '../static/icons/icono_asesoria.png'
import investmentIcon from '../static/icons/icono_inversion.png'
import performanceIcon from '../static/icons/icono_rendimiento.png'
import growthIcon from '../static/icons/icono_crecimiento.png'
import fastIcon from '../static/icons/icono_rapidez.png'
import insuranceIcon from '../static/icons/icono_seguro.png'
import moneyIcon from '../static/icons/icono_dinero.png'
import coinsIcon from '../static/icons/icono_monedas.png'
import { getComponent } from '../services/ComponentServices'

export default function Benefits() {

    const [icons, seticons] = useState([])

    const getIcons = async () => {
        const response = await getComponent(6, 2)

        if (response.status === 200)
            seticons(response.data)
    }

    useEffect(() => {
        getIcons()
    }, [])


    return (
        <Container>
            <Grid display="flex" container justifyContent="center">
                {
                    icons.map((i: any) => (
                        <Paper key={i.id} elevation={0} sx={{ width: 180, m: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                            <img src={i.image} width={80} />
                            <Typography variant="body2">{i.text}</Typography>
                        </Paper>
                    ))
                }
            </Grid>
        </Container>
    )
}
