import { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from "@mui/material";
import "../../assets/styles/OneBlogStyle.css";
import { get_content_blog } from '../../services/BlogServices';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate, useParams } from 'react-router-dom';
import ParagraphText from '../../components/pages/ParagraphText';
import SkeletonBlog from '../../components/pages/SkeletonBlog';
import Chip from '@mui/material/Chip';

function DetailBlog() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    const [labels, setLabels] = useState([]);
    const { id } = useParams();
    const [values, setValues] = useState({
        titulo: '',
        fecha: '',
        autor: '',
        imagen: '',
        intro: ''
    });

    useEffect(() => {
        const obtenerOneBlog = async () => {
            setLoading(true);
            const response = await get_content_blog(id!);
            try {
                if (response.status === 200) {

                    const listaOrdenada: any[any] = [...response.data.list].sort((a, b) => a.order - b.order);

                    setContent(listaOrdenada);

                    //setContent(response.data.list);
                    setLabels(response.data.listLabel);
                    let portada = response.data.blog;
                    const fechaFormato = (response.data.blog.lastdate === null) ? '' : format(new Date(response.data.blog.lastdate.replace(/-/g, '/')), "dd-MMMM-yyyy", { locale: es });
                    setValues({
                        ...values,
                        titulo: portada.tittle!,
                        fecha: fechaFormato!,
                        imagen: portada.img!,
                        intro: portada.intro,
                    })
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        obtenerOneBlog();
    }, [id]);

    const handleClickLabel = (label: any) => {
        navigate(`/blog/`, { state: { params: { label: `${label.value}`, name: `${label.name}` } } });
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ display: 'flex', mb: 5 }}
        >
            {loading ? <SkeletonBlog /> :
                <>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                            sx={{
                                display: 'flex', bgcolor: '#001933', color: 'white', textAlign: 'justify',
                                alignItems: 'center', p: { md: 10, sm: 2, xs: 2 }, pr: 0,
                            }}
                        >
                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                <h4 className='blog-title-b'>
                                    {values.titulo!}
                                </h4>
                                <ParagraphText text={values.intro!} intro={true} />
                                <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: 10 }}>
                                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                        {values.fecha!}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', }}>
                                {values.imagen ?
                                    <Grid item lg={5} md={5} sm={5} xs={12} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', }}>
                                        <img
                                            className='blog-img-b'
                                            src={`${values.imagen}`}
                                            loading="lazy"
                                            alt={'portada'}
                                            aria-hidden="true"
                                        />
                                    </Grid>
                                    : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Container maxWidth="xl">
                        {content.length > 0 ?
                            content.map((item: any, index) => {
                                return (
                                    <Grid item lg={8} xs={12} key={index} sx={{ pl: { xs: 0, md: 4 }, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'justify' }}>
                                        {item.idtype === 1 ?
                                            < ParagraphText text={item.text} />
                                            :
                                            item.idtype === 2 ?
                                                <img key={index} alt={"imagen"} style={{ maxWidth: "100%", height: 'auto' }}
                                                    src={item.file}
                                                    loading="lazy"
                                                />
                                                : item.idtype === 3 ?
                                                    <Button href={item.link} variant="contained" color="primary" sx={{ mt: 1, mb: 1, borderRadius: '15px' }}>{item.text}</Button>
                                                    : null}
                                    </Grid>
                                )
                            })
                            : null}
                        {labels.length > 0 ?
                            <Grid item lg={8} xs={12} sx={{ mt: 2, ml: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textAlign: "initial" }}>
                                <div>
                                    <Typography variant="body2" sx={{ ml: 1, display: 'flex' }}>Etiquetas:</Typography>
                                    {labels.map((item: any, idx: any) => {
                                        return (<Chip label={`${item.name!}`} key={idx} sx={{ mr: 1, mb: 1, ':hover': { bgcolor: "#001230", color: 'white' } }} onClick={() => handleClickLabel(item)} />)
                                    })}
                                </div>
                            </Grid>
                            : null
                        }
                    </Container>
                </>
            }
        </Grid>
    );
}

export default DetailBlog;