import { Container, Divider, Grid, Paper, Typography } from '@mui/material'

export default function Branches() {
    return (
        <Container sx={{ marginTop: 15 }}>
            <Typography variant="h3" alignSelf="start">Conoce nuestras sucursales</Typography>
            <Paper elevation={5} sx={{ margin: 2 }}>
                <Grid container>
                    <Grid item xs={12} lg={6} textAlign="start" p={3}>
                        <Typography variant="h5">Oficinas Corporativo</Typography>
                        <Divider sx={{ border: '1px solid red' }}></Divider>
                        <Typography>Paseo de los Insurgentes #301, Colonia Los Paraísos, C.P. 37328</Typography>
                        <Typography><b>Teléfono:</b> (477) 461 47 00</Typography>
                        <Typography><b>Horario Corporativo:</b> Lunes a viernes 08:00 a 18:00 hrs. <br />
                            <b>Horario Sucursal:</b> Lunes a viernes 08:00 a 18:00 hrs. Sábado 09:00 a 14:00 hrs.</Typography>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <iframe className="iframe" src={`https://maps.google.com/?q=21.142311079045484, -101.6908436160667&z=14&t=m&output=embed`} height={330} style={{ border: 0, width: '100%' }}></iframe>
                    </Grid>
                </Grid>
            </Paper>

            {/* <Paper elevation={5} sx={{ margin: 2 }}>
                <Grid container>
                    <Grid item xs={12} lg={6} textAlign="start" p={3}>
                        <Typography variant="h5">Sucursal Mariano Escobedo</Typography>
                        <Divider sx={{ border: '1px solid red' }}></Divider>
                        <Typography>Blvd. Mariano Escobedo Ote. #2807, Colonia Oriental, C.P 37510 León, Guanajuato.</Typography>
                        <Typography><b>Teléfonos:</b> (477) 258 62 42 / (477) 258 62 43 / (477) 299 22 72</Typography>
                        <Typography><b>Horario:</b> Lunes a viernes 09:00 a 19:00 hrs. Sábado 09:00 a 14:00 hrs.</Typography>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <iframe className="iframe" src={`https://maps.google.com/?q=21.106792094397015, -101.65656476998427&z=14&t=m&output=embed`} height={330} style={{ border: 0, width: '100%' }}></iframe>
                    </Grid>
                </Grid>
            </Paper> */}

            <Paper elevation={5} sx={{ margin: 2 }}>
                <Grid container>
                    <Grid item xs={12} lg={6} textAlign="start" p={3}>
                        <Typography variant="h5">Sucursal Centro</Typography>
                        <Divider sx={{ border: '1px solid red' }}></Divider>
                        <Typography>Blvd. López Mateos Pte. Número 303, Col. Centro, 37000 León, Gto.</Typography>
                        <Typography><b>Horario:</b> Lunes a viernes 08:00 a 18:00 hrs. Sábado 09:00 a 14:00 hrs.</Typography>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <iframe className="iframe" src={`https://maps.google.com/?q=21.125968412500466, -101.68364260674555&z=14&t=m&output=embed`} height={330} style={{ border: 0, width: '100%' }}></iframe>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}
