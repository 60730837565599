import Visualizador from "./Visualizador";



function CostosComisiones() {


    return (
        <Visualizador type={9} ></Visualizador>
    );
}

export default CostosComisiones;