import { Container, Typography } from '@mui/material'
import { useEffect, useState } from "react";
import { getComponent } from "../../services/ComponentServices";
import { Divider } from 'ui-neumorphism';

interface VisualizadorProps {
    type: number
    datas?: any
}

const Visualizador = ({ type, datas }: VisualizadorProps) => {

    const [data, setData] = useState<any | null>(null)
    const [loading, setloading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [enabledService, setEnabledService] = useState(true);

    useEffect(() => {
        window.scroll(0,0)

        const obtenerData = async () => {
            setloading(true);
            const response = await getComponent(type, 2);
            try {
                setData(response.data[0]);
                setloading(false);
                setIsError(false);
            } catch (error) {
                setloading(false);
                setIsError(true)
            }
        };
        obtenerData();
    }, []);
    return (
        <>
            {!loading ?
                <Container sx={{ height: '100%', marginTop:2 }}>
                    <div style={{ height: 900, margin: 2 }}>
                        <Typography variant="h4" color="#001933">{data.componenttype.name}</Typography>
                        <Divider></Divider>
                        <object data={data.image} type="application/pdf" width={'100%'} height={'100%'}>
                        </object>
                    </div>
                </Container > :
                null}
        </>
    );
}

export default Visualizador;