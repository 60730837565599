import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar, DateTimePicker, deDE, esES, TimePicker } from '@mui/x-date-pickers';
import { MAIN_COLOR } from '../../utils/constants';

export default function ApointmentModal(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const sevenPM = dayjs().set('hour', 19).startOf('hour');
  const nineAM = dayjs().set('hour', 9).startOf('hour');

  const [date, setdate] = useState<any>()
  const [time, settime] = useState<any>()
  const [errorStatus, seterrorStatus] = useState("")
  const [boolLabelError, setboolLabelError] = useState(false)
  const [errorLabel, seterrorLabel] = useState("")

  const agendarFecha = () => {
    if (boolLabelError === false && date !== undefined) {
      props.setMeeting({
        day: date,
        time: time
      });
      props.closeWithResponse(true)
      props.close()
    } else {
      seterrorLabel("Favor de escoger fecha y hora")
      setboolLabelError(true)
    }
  }

  const handleError = (err: any) => {
    seterrorStatus(err)
  }

  const isWeekend = (date: Dayjs) => {
    const day = date.day();
  
    return day === 0 || day === 6;
  };

  useEffect(() => {
    if (errorStatus === "minTime") {
      setboolLabelError(true)
      seterrorLabel("La hora mínima es de 9:00 AM, favor de agendar en otro horario.")
    } else if (errorStatus === "maxTime") {
      setboolLabelError(true)
      seterrorLabel("La hora máxima es de 7:00 PM, favor de agendar en otro horario.")
    } else {
      setboolLabelError(false)
    }
  }, [errorStatus])


  return (
    <Dialog open={props.open} onClose={props.close} title="Agenda una cita" fullScreen={fullScreen}>
      <DialogTitle id="responsive-dialog-title">
      <Fab variant="circular" size='small' sx={{ position: 'absolute', right: 20, background: `${MAIN_COLOR}`, color: 'white', ':hover': { color: 'red' } }} onClick={() => props.close()}><CloseIcon></CloseIcon></Fab>
        <Typography variant="h4">¡Agenda una cita!</Typography>
        <Typography variant="h6" color="red">Uno de nuestros ejecutivos con gusto te contactará</Typography>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DateCalendar
            disablePast
            shouldDisableDate={isWeekend}
            defaultValue={dayjs(new Date())}
            value={dayjs(date)}
            onChange={(e: any) => setdate(e?.$d.toString())}></DateCalendar>

          <TimePicker
            disablePast
            label="Horario de atención"
            sx={{ width: '100%' }}
            value={time}
            maxTime={sevenPM}
            minTime={nineAM}
            onError={(e) => handleError(e)}
            onChange={(e: any) => settime(new Date(e.$d).toLocaleTimeString())}
          />
        </LocalizationProvider>

        {
          boolLabelError && <Alert sx={{ marginTop: 2 }} severity='error'>{errorLabel}</Alert>
        }
      </DialogContent>
      <DialogActions onClick={() => agendarFecha()} sx={{ background: '#ff1719', cursor: 'pointer', justifyContent: 'center' }}>
        <Typography variant="h4" color="white" fontWeight="bold">Agendar</Typography>
      </DialogActions>
    </Dialog>
  )
}
