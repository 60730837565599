import {API_URL} from "../constants";

const questionAgreementEndpoint = API_URL + "question-agreement";

export const GET_DATA_BY_AGREEMENT = (id: number) => questionAgreementEndpoint + `/public/byAgreement/${id}`;

export const GET_AGREEMENT_INFO = (id:number) => API_URL + `agreements/public/${id}`

export const POST_SAVE_NEW_AGREEMENT_LEAD = API_URL + `lead/public/AgreementLead/`

export const GET_PRODUCTSNAME_LEAD = `${API_URL}product/public/productsNameInLeads`;
