import { useEffect } from 'react';
import Visualizador from './Visualizador';
import { CircularProgress } from '@mui/material';

export default function AvisoDePrivacidad() {
    useEffect(() => {
        // Cambiar la URL a la dirección HTTP deseada
        window.location.href = 'https://avantthya.com.mx/blog/aviso-de-privacidad/9/';
      }, []); 
    return (
        <CircularProgress />
        //<Visualizador type={14} ></Visualizador>
    );
}
