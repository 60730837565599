import { Box, Skeleton } from '@mui/material'
import React from 'react'

export default function CardsSkeleton() {
    return (
        <div style={{ display: 'flex' }}><Box sx={{ pt: 0.5, m: 2 }}>
            <Skeleton variant="rectangular" animation="wave" width={260} height={150}></Skeleton>
            <Skeleton />
            <Skeleton width="60%" />
        </Box>
            <Box sx={{ pt: 0.5, m: 2 }}>
                <Skeleton variant="rectangular" animation="wave" width={260} height={150}></Skeleton>
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
            <Box sx={{ pt: 0.5, m: 2 }}>
                <Skeleton variant="rectangular" animation="wave" width={260} height={150}></Skeleton>
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
            <Box sx={{ pt: 0.5, m: 2 }}>
                <Skeleton variant="rectangular" animation="wave" width={260} height={150}></Skeleton>
                <Skeleton />
                <Skeleton width="60%" />
            </Box></div>
    )
}
