import axios from 'axios'
import { GET_ALL_CREDITS, GET_CREDIT, GET_CREDIT_RESULTS, GET_CREDIT_RESULTS_MODAL, GET_RANK_CREDITS, POST_LEAD } from '../utils/endpoints/CreditEndpoints'

export const getAllCredits = () => {
    return axios.get(GET_ALL_CREDITS)
}

export const getCredit = (id: any) => {
    return axios.get(GET_CREDIT(id));
}

export const getRankCredits = () => {
    return axios.get(GET_RANK_CREDITS)
}

export const getCreditResults = (idCredit: any, idPlazo: any, amount: any) => {
    return axios.get(GET_CREDIT_RESULTS(idCredit, idPlazo, amount))
}

export const getAmortizationTable = (idCredit: any, idPlazo: any, amount: any) => {
    return axios.get(GET_CREDIT_RESULTS_MODAL(idCredit, idPlazo, amount))
}

export const postNewLead = (lead: any) => {
    return axios.post(POST_LEAD, lead)
}
