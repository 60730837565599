import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function SkeletonBlog() {
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} xs={12} sm={12} md={6} margin={2}>
        <div style={{display:'flex', margin:10,}}>
        <Skeleton variant="rounded" width="70%"  height={90}  sx={{mr:1}} />
        <Skeleton variant="rounded" width="30%" height={90} />
        </div>
        <div style={{display:'flex', margin:10}}>
        <Skeleton variant="rounded" width="100%"  height={400}  />
        </div>
      </Grid>
    </Grid>
  );
}