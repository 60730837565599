import { Alert,  Container,  Grid,  Snackbar, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ApointmentModal from '../components/general/ApointmentModal';
import {MAIN_COLOR, SECONDARY_COLOR} from '../utils/constants';
import { ProductContext } from '../contexts/GeneralContexts';
import CreditResultsModal from '../components/general/CreditResultsModal';
import "../assets/styles/CalculatorStyle.css";
import { useMediaQuery } from '@mui/material';
import RequestForm from "../components/general/RequestForm";
import {getAllCredits, getCreditResults} from "../services/CreditService";

export default function Form() {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const [openModalApointment, setopenModalApointment] = useState(false)
    const [openModalRequest, setopenModalRequest] = useState(false)
    const [openModalCreditResults, setopenModalCreditResults] = useState(false)

    const [products, setproducts] = useState<any>([])
    const [boolLimite, setboolLimite] = useState(false)
    const [selectedProduct, setselectedProduct] = useState<any>({})
    const [creditResults, setcreditResults] = useState<any>({})
    const [periodicity, setperiodicity] = useState({
        value: 0,
        name: '',
        pluralName: '',
        id: 0
    })
    const [loaderResult, setloaderResult] = useState(false)
    const [boolError, setboolError] = useState(false)
    const [boolLock, setboolLock] = useState(true)
    const [boolAlert, setboolAlert] = useState(false)
    const [meet, setmeet] = useState({})
    const [selectedPlazo, setselectedPlazo] = useState("")

    const firstFabRef = useRef<any>();

    const productContext = useContext(ProductContext);

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    const getProducts = async () => {
        const response = await getAllCredits()

        if (response.status == 200) {
            setproducts(response.data.filter((r: any) => r.product.producttype.id === 2))
            setselectedProduct({
                ...response.data[0],
                cantidad: response.data[0].min
            })
            await obtenerResultadosIniciales(response.data[0])
        }

    }
    const obtenerResultados = async (p: any) => {
        try {
            setloaderResult(true)
            const response = await getCreditResults(p.id, periodicity.id, p.cantidad)

            if (response.status === 200) {
                setcreditResults(response.data)
                setloaderResult(false)
                setboolLock(false)
                setboolError(false)
            } else {
                setloaderResult(false)
            }
        } catch (error) {
            setboolError(true)
            setloaderResult(false)
        }
    }
    const obtenerResultadosIniciales = async (p: any) => {
        try {
            setloaderResult(true)
            setperiodicity({
                value: p.rangetimecreditList[0].rangetime.value,
                name: p.rangetimecreditList[0].rangetime.periodicity.name,
                pluralName: p.rangetimecreditList[0].rangetime.periodicity.pluralName,
                id: p.rangetimecreditList[0].idrangetime
            })

            const response = await getCreditResults(p.id, p.rangetimecreditList[0].idrangetime, p.min)

            if (response.status === 200) {
                setcreditResults(response.data)
                setloaderResult(false)
                setboolLock(false)
                setboolError(false)
            } else {
                setloaderResult(false)
            }
        } catch (error) {
            setboolError(true)
            setloaderResult(false)
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setboolAlert(false);
    };

    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        obtenerResultados(selectedProduct)
    }, [selectedProduct])

    useEffect(() => {
        if (firstFabRef.current) {
            firstFabRef.current.focus()
        }

    }, []);


    useEffect(() => {
        if (Object.keys(productContext[0]).length !== 0) {
            setselectedProduct({ ...productContext[0], cantidad: productContext[0].min })
            setperiodicity({
                value: productContext[0].rangetimecreditList[0].rangetime.value,
                pluralName: productContext[0].rangetimecreditList[0].rangetime.periodicity.pluralName,
                name: productContext[0].rangetimecreditList[0].rangetime.periodicity.name,
                id: productContext[0].rangetimecreditList[0].idrangetime
            })
            const element = document.getElementById("calculator")

            if (Object.keys(selectedProduct).length !== 0)
                element?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [productContext])
    return (
        <Container sx={{ p: 1 }} id="calculator">
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} alignSelf="center" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: "center" }} mt={3}>
                        <RequestForm
                            products={products}
                            periodicity={periodicity}
                            meet={meet}
                            product={Object.keys(selectedProduct).length !== 0 && selectedProduct}
                            open={openModalRequest}
                            close={() => setopenModalRequest(false)}
                            closeWithResponse={(e: any) => { if (e === true) setboolAlert(true) }}/>
                </Grid>

                <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" marginTop={3} padding={2} sx={{
                    borderRadius: '15px', backgroundColor: 'rgba(0,0,0,0,0.5)', backgroundImage: 'linear-gradient(to bottom right, rgba(255,255,255,0.7), rgba(255,255,255,0.7))'
                    , backdropFilter: 'blur(10px)', boxShadow: '10 10 10 rgba(30,30,30,0.7)'
                }}>
                    <Typography variant="body1" fontWeight={700} color={`${MAIN_COLOR}`}>*límite sujeto a políticas de crédito y autorización del Comité de Crédito</Typography>
                    <Typography variant="body2" textAlign="justify">Condiciones sujetas a políticas de crédito vigente y de acuerdo a las condiciones específicas del producto
                        "{selectedProduct.product?.name}". <b style={{ fontSize: 23 }}>CAT PROMEDIO {creditResults.catX}% Sin IVA. CAT (Costo Anual Total).</b> Para fines informativos y de comparación, fecha de cálculo {new Intl.DateTimeFormat("es-ES", {
                            weekday: "long",
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        }).format(new Date())}
                        , para un crédito de {formatter.format(+selectedProduct?.cantidad || 0)} pesos a un plazo de {periodicity.value} {periodicity.pluralName} y tasa fija del {selectedProduct?.rate}% anual. Vigencia al {new Intl.DateTimeFormat("es-ES", {
                            weekday: "long",
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        }).format(new Date())}. Cobertura Nacional.
                        Sujeto a aprobación de crédito. Los datos que se muestran no corresponden a una cotización oficial, es únicamente una referencia de simulación de crédito.
                        Sujeto a cambios sin previo aviso. Informes sobre requisitos, términos, comisiones y condiciones de contratación del producto: www.avantthya.com.mx</Typography>
                </Grid>
            </Grid>

            <Snackbar open={boolAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} variant="filled" severity="success">¡Has enviado tu cotización de crédito con éxito! Nos pondremos en contacto contigo lo antes posible.</Alert>
            </Snackbar>

            <ApointmentModal
                open={openModalApointment}
                close={() => { setopenModalApointment(false) }}
                setMeeting={(e: any) => setmeet(e)}
                closeWithResponse={(e: any) => { if (e === true) setopenModalRequest(true) }}></ApointmentModal>
            <CreditResultsModal
                credit={creditResults}
                product={Object.keys(selectedProduct).length !== 0 && selectedProduct}
                periodicity={periodicity}
                open={openModalCreditResults}
                close={() => setopenModalCreditResults(false)}></CreditResultsModal>
        </Container >
    )
}
