import axios from "axios"
import { GET_CONTENT_BLOG, GET_LIST_BLOGS, GET_LIST_BLOGS_BY_LABELS } from "../utils/endpoints/BlogEndpoints";


export const get_list_blog = (page:string, size:string) => { return axios.get(GET_LIST_BLOGS(page,size));
}

export const get_list_blog_by_label = (page:string, size:string,label:string) => { return axios.get(GET_LIST_BLOGS_BY_LABELS(page,size,label));
}

export const get_content_blog = (id:string) => { return axios.get(GET_CONTENT_BLOG(id));
}