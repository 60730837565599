import {
    GET_AGREEMENT_INFO,
    GET_DATA_BY_AGREEMENT, GET_PRODUCTSNAME_LEAD,
    POST_SAVE_NEW_AGREEMENT_LEAD
} from "../utils/endpoints/QuestionAgreementsEndpoints";
import axios from "axios";
import {AgreementLeadStructure} from "../pages/Agreements/AgreementDataStructures";


export const getAllQuestionsByAgreement = (id: number) => {
    return axios.get(GET_DATA_BY_AGREEMENT(id))
}


export const getAgreementInfo  = (id: number) =>{
    return axios.get(GET_AGREEMENT_INFO(id))
}

export const SaveNewAgreementLead = (lead: AgreementLeadStructure) => {
    return axios.post(POST_SAVE_NEW_AGREEMENT_LEAD, lead)
}

export const getProductsNameInLeads = () => {
    return axios.get(GET_PRODUCTSNAME_LEAD)
}