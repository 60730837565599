import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { SECONDARY_COLOR, logo, } from '../../utils/constants';
import { useNavigate } from 'react-router-dom'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { MAIN_COLOR } from '../../utils/constants';
import { CardMedia, Container, Menu, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

export default function NavBar() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = useNavigate();



    return (
        <AppBar position="fixed" sx={{ background: `${MAIN_COLOR}` }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ display: 'flex' }}>
                    <Stack sx={{ flexGrow: { sm: 0, md: 1 }, m: 0, p: 0 }}>
                        <CardMedia
                            component="img"
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                flexGrow: { sm: 'none', md: 1 },
                                mr: 2,cursor:'pointer',
                                alignItems: 'center', maxWidth: { xs: 60, sm: 80, md: 120 }, maxHeight: { sm: 40, md: 80 },
                            }}
                            image={`${logo}`} loading="lazy"
                            alt="logo ultratasas"
                            onClick={() => navigate("/")}
                        />
                    </Stack>
                    <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' }, alignItems: 'center', }}>
                        <Typography
                            variant="body2"
                            noWrap
                            component="a"
                            href={"/nosotros"}
                            sx={{
                                mr: 4, color: 'white',mt:1.5,
                                fontSize: { sm: '10px', md: '13px', xl: '14px', lg: '14px' },
                                letterSpacing: { sm: 'none', md: '.05rem' },
                                textDecoration: 'none',
                                transition: '0.2s', '&:hover': { transform: 'scale(1.1)', },
                            }}
                        >
                            Conoce AVANTTHYA
                        </Typography>
                       <Typography
                            variant="body2"
                            noWrap
                            component="a"
                            href={"/blog/"}
                            sx={{
                                mr: 4, color: 'white',mt:1.5,
                                fontSize: { sm: '10px', md: '13px', xl: '14px', lg: '14px' },
                                letterSpacing: { sm: 'none', md: '.05rem' },
                                textDecoration: 'none',
                                transition: '0.2s', '&:hover': { transform: 'scale(1.1)', },
                            }}
                        >
                            Blog
                        </Typography> 
                        <Typography
                            variant="body2"
                            noWrap
                            component="a"
                            target={'_blank'} 
                            rel="noopener noreferrer"
                            href={"https://ultratasas.com/"}
                            sx={{
                                mr: 2, color: 'white',mt:1.5,
                                fontSize: { sm: '10px', md: '13px', xl: '14px', lg: '14px' },
                                letterSpacing: { sm: 'none', md: '.05rem' },
                                textDecoration: 'none',
                                transition: '0.2s', '&:hover': { transform: 'scale(1.1)', },
                            }}
                        >
                            Inversión
                        </Typography>
                        {/* <Fab variant='extended' color='inherit' size='small'
                            sx={{
                                bgcolor: `${SECONDARY_COLOR}`, textTransform: 'none',
                                fontSize: { sm: '8px', md: '9px', xl: '12px', lg: '10px' },
                                letterSpacing: { sm: 'none', md: '.05rem' }, pl: 2, pr: 2, mr: 1,
                                '&:hover': { color: `${SECONDARY_COLOR}`, transform: 'scale(1.1)', }, transition: '0.2s',
                            }}>Solicita tu crédito</Fab> */}
                
                        <IconButton onClick={() => navigate("/sucursales")}
                            sx={{ color: 'white', display: 'flex', flexDirection: 'column', ml: 1, mb: 0, pb: 2, transition: '0.2s', '&:hover': { transform: 'scale(1.1)', }, }}>
                            <LocationOnOutlinedIcon />
                            <Typography variant='caption' sx={{ fontWeight: 500 }}>Sucursales</Typography>
                        </IconButton>

                    </Box>
                    { /* AppBarr vista celular */}
                    <Stack sx={{/* flexGrow:1, */ display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' }, }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ flexGrow: 1, color: 'white' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                p: 0, display: { xs: 'block', md: 'none' }, '.MuiMenu-paper': { bgcolor: 'white' },
                            }}
                        >
                            <MenuItem onClick={() => navigate("/")} sx={{ backgroundColor: "white", m: 0, ml: 1, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <Typography
                                    variant='body2'
                                    noWrap
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 4, color: '#00021c',
                                        letterSpacing: { sm: 'none', md: '.05rem' },
                                        textDecoration: 'none',
                                        transition: '0.2s', '&:hover': { transform: 'scale(1.1)', color: '#00021c' },
                                    }}
                                >
                                    Inicio
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/nosotros")} sx={{ backgroundColor: "white", m: 0, ml: 1, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <Typography
                                    variant='body2'
                                    noWrap
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 4, color: '#00021c',
                                        letterSpacing: { sm: 'none', md: '.05rem' },
                                        textDecoration: 'none',
                                        transition: '0.2s', '&:hover': { transform: 'scale(1.1)', color: '#00021c' },
                                    }}
                                >
                                    Conoce AVANTTHYA
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/blog")} sx={{ backgroundColor: "white", m: 0, ml: 1, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <Typography
                                    variant='body2'
                                    noWrap
                                    sx={{
                                        fontWeight: 'bold',
                                        mr: 4, color: '#00021c',
                                        letterSpacing: { sm: 'none', md: '.05rem' },
                                        textDecoration: 'none',
                                        transition: '0.2s', '&:hover': { transform: 'scale(1.1)', color: '#00021c' },
                                    }}
                                >
                                    Blog
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => window.open("https://ultratasas.com/", "blank")} sx={{ backgroundColor: "white", m: 0, ml: 1, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    sx={{
                                        mr: 4, color: '#00021c', fontWeight: 'bold',
                                        letterSpacing: { sm: 'none', md: '.05rem' },
                                        textDecoration: 'none',
                                    }}
                                >
                                    Inversión
                                </Typography>
                            </MenuItem>
                            {/* <MenuItem sx={{ backgroundColor: "white", m: 0, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <Fab variant='extended' color='inherit' size='small'
                                    sx={{
                                        bgcolor: '#00021c', textTransform: 'none', fontWeight: 'bold',
                                        fontSize: { sm: '11px', md: '15px', xl: '12px', lg: '10px' },
                                        letterSpacing: { sm: 'none', md: '.05rem' }, pl: 2, pr: 2, mr: 1,
                                        '&:hover': { color: `${SECONDARY_COLOR}`, transform: 'scale(1.1)', }, transition: '0.2s', color: 'white'
                                    }}>Solicita tu crédito</Fab>
                            </MenuItem> */}
                            <MenuItem onClick={() => navigate("/sucursales")} sx={{ backgroundColor: "white", m: 0,ml:-1, ":hover": { backgroundColor: "#ebf0fe", color: '#00021c', transform: 'scale(1.1)' } }}>
                                <IconButton
                                    sx={{ color: '#00021c', display: 'flex', flexDirection: 'row', mb: 0, transition: '0.2s', '&:hover': { transform: 'scale(1.1)', }, }}>
                                    <LocationOnOutlinedIcon />
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Sucursales</Typography>
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Stack>
                    <Stack sx={{ display: { sm: 'flex', md: 'none' }, flexGrow: { sm: 0, md: 1 }, m: 0, p: 0 }}>
                        <CardMedia
                            component="img"
                            sx={{
                                flexGrow: { sm: 'none', md: 1 },
                                mr: 2,
                                alignItems: 'center', maxWidth: { xs: 60, sm: 80, md: 120 }, maxHeight: { sm: 40, md: 80 },
                            }}
                            image={`${logo}`} loading="lazy"
                            alt="logo ultratasas"
                            onClick={() => navigate("/")}
                        />
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    )
}