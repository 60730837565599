import { CircularProgress, Dialog, DialogContent, DialogTitle, Fab, Grid, LinearProgress, Modal, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getAmortizationTable } from '../../services/CreditService';
import { styled } from '@mui/material/styles';
import { Divider } from 'ui-neumorphism';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { logo_rojo, MAIN_COLOR } from '../../utils/constants';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

export default function CreditResultsModal(props: any) {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            fontWeight: 'bold'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const [dataTable, setdataTable] = useState<any>({})
    const [loader, setloader] = useState(false)

    const modalRef = useRef<any>()

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    const tablaAmortizacion = async () => {
        setloader(true)
        if (props.product && props.periodicity) {
            const response = await getAmortizationTable(props.product.id, props.periodicity.id, props.product.hasOwnProperty("cantidad") ? props.product.cantidad : props.product.min)

            if (response.status === 200) {
                setdataTable(response.data)
                setloader(false)
            }
        }
    }

    const handleDownload = async () => {
        const btn = document.getElementById("btnDescargar") as HTMLElement
        btn.style.display = "none"

        const datos = document.getElementById("datosContacto") as HTMLElement
        datos.style.display = "block"

        const printElement = modalRef.current;
        if (printElement) {
            printElement.style.background = 'none'
            printElement.style.boxShadow = 'none'
            window.print();
        }

    };

    useEffect(() => {
        const printElement = modalRef.current;

        return () => {
            if (printElement) {
                printElement.classList.remove('print-only');
            }
        };
    }, []);


    useEffect(() => {
        tablaAmortizacion()
    }, [props.open])

    return (
        <Dialog ref={modalRef} id="tabla" open={props.open} onClose={props.close} title="Tabla de amortización de crédito" maxWidth="md" fullWidth>
            <DialogTitle id="responsive-dialog-title" style={{ display: 'flex' }}>
                <Fab variant="circular" size='small' sx={{ position: 'absolute', right: 20, background: `${MAIN_COLOR}`, color: 'white', ':hover': { color: 'red' } }} onClick={() => props.close()}><CloseIcon></CloseIcon></Fab>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2}>
                        <img src={logo_rojo} style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                        <Stack direction="column" justifyContent="center" ml={3}>
                            <Typography sx={{ fontSize: { xs:'18px', sm:'22px' , md:'30px' } }}>Tabla de amortización para tu <b style={{ color: '#010130' }}>crédito:</b></Typography>
                            <Typography sx={{ fontSize: { xs:'14px', sm:'18px' , md:'22px' } }} color="red">{props.product?.product?.title}</Typography>
                        </Stack>
                    </Grid>
                </Grid>

            </DialogTitle>

            <DialogContent>
                <Divider></Divider>
                <Grid container mb={1}>
                    <Grid item xs={12} md={6} display="flex" flexDirection="column">
                        <Typography variant='caption' fontSize={14}>Fecha de elaboración: {new Intl.DateTimeFormat("es-ES", {
                            weekday: "long",
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        }).format(new Date())}</Typography>
                        <Typography variant='caption' fontSize={14}>Monto del crédito: {formatter.format(props.credit.amount)}</Typography>
                        <Typography variant='caption' fontSize={14}>Plazo: {props.periodicity.value}</Typography>
                        <Typography variant='caption' fontSize={14}>Frecuencia: {props.periodicity.pluralName}</Typography>
                        <Typography variant='caption' fontSize={14}>Tasa de interés anual ordinaria: {props.credit.rate}%</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} display="flex" flexDirection="column">
                        <Typography variant='caption' fontSize={14}>Comisión de apertura: {formatter.format(props.credit.commissionPlus1)}</Typography>
                        {/* <Typography variant='caption' fontSize={14}>IVA de comisión apertura: {formatter.format(props.credit.commissionPlus1Iva)}</Typography> */}
                        <Typography variant='caption' fontSize={14}>Comisión por mantenimiento: {formatter.format(props.credit.commissionPlus2)}</Typography>
                        {/* <Typography variant='caption' fontSize={14}>IVA de comisión mantenimiento: {formatter.format(props.credit.commissionPlus2Iva)}</Typography> */}
                        <Typography variant='caption' fontSize={14}>IVA de las comisiones y de los intereses: {formatter.format(props.credit.IvaOfCommisionsAndInterests)}</Typography>
                        <Typography variant='caption' fontSize={14}>Monto del seguro: {formatter.format(props.credit.lifeInsurance)}</Typography>
                        <Fab variant="extended" sx={{ background: `${MAIN_COLOR}`, color: 'white', ':hover': { color: `${MAIN_COLOR}` } }} id="btnDescargar" onClick={() => handleDownload()} size="small"><PrintIcon></PrintIcon> Imprimir cotización</Fab>
                    </Grid>
                </Grid>

                {
                    loader ? <LinearProgress color="error"></LinearProgress> :
                        Object.keys(dataTable).length > 0 &&
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ fontWeight: 'bold' }}>
                                    <StyledTableRow>
                                        <TableCell>Núm. Pago</TableCell>
                                        <TableCell>Saldo Insoluto</TableCell>
                                        <TableCell>Amortización de capital</TableCell>
                                        <TableCell>Intereses</TableCell>
                                        <TableCell>IVA de los intereses</TableCell>
                                        <TableCell>Pago total del periodo</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(dataTable).length > 0 && dataTable?.pagos.map((d: any, i: any) => (
                                        <StyledTableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <StyledTableCell>{d.number}</StyledTableCell>
                                            <StyledTableCell>{formatter.format(d.initialbalance)}</StyledTableCell>
                                            <StyledTableCell align='center'>{formatter.format(d?.capital)}</StyledTableCell>
                                            <StyledTableCell>{formatter.format(d.interest)}</StyledTableCell>
                                            <StyledTableCell>{formatter.format(d.iva)}</StyledTableCell>
                                            <StyledTableCell>{formatter.format(d.pago)}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow>
                                        <StyledTableCell>Total</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">{formatter.format(dataTable?.total.capital)}</StyledTableCell>
                                        <StyledTableCell>{formatter.format(dataTable?.total.inseres)}</StyledTableCell>
                                        <StyledTableCell>{formatter.format(dataTable?.total.iva)}</StyledTableCell>
                                        <StyledTableCell>{formatter.format(dataTable?.total.total)}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                }

                <br />
                <Typography variant="body2" textAlign="justify">Condiciones sujetas a políticas de crédito vigente y de acuerdo a las condiciones específicas del producto
                    "{props.product?.product?.name}". <b style={{ fontSize: 23 }}>CAT PROMEDIO {dataTable.catX}% Sin IVA. CAT (Costo Anual Total).</b> Para fines informativos y de comparación, fecha de cálculo {new Intl.DateTimeFormat("es-ES", {
                        weekday: "long",
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }).format(new Date())}
                    , para un crédito de {formatter.format(+props.product?.cantidad || 0)} pesos a un plazo de {props.periodicity.value} {props.periodicity.pluralName} y tasa fija del {props.product?.rate}% anual. Vigencia al {new Intl.DateTimeFormat("es-ES", {
                        weekday: "long",
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }).format(new Date())}. Cobertura Nacional.
                    Sujeto a aprobación de crédito. Los datos que se muestran no corresponden a una cotización oficial, es únicamente una referencia de simulación de crédito.
                    Sujeto a cambios sin previo aviso. Informes sobre requisitos, términos, comisiones y condiciones de contratación del producto: www.avantthya.com.mx</Typography>

                <div id="datosContacto" style={{ display: 'none', fontSize: 18, justifyContent: 'center', textAlign: 'center', marginTop: '5%' }}>
                    <Typography variant="h4">Contacto</Typography>
                    <Divider></Divider>
                    <Typography><PhoneIcon></PhoneIcon>Teléfono: (477) 461 47 00</Typography>
                    <Typography><AccessTimeIcon></AccessTimeIcon>Horarios de atención: Lun a Vie 09:00 a 19:00 hrs</Typography>
                </div>
            </DialogContent>
        </Dialog>
    )
}
