import { Container, Grid, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "../assets/styles/Footer.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { aviso_privacidad, logo_avantthya } from "../utils/constants";
import buro from "../assets/img/buro.jpg";
import cnbv from "../assets/img/cnvb.png";
import condusef from "../assets/img/condusef.png";
import { useEffect, useState } from "react";
import { getComponent } from "../services/ComponentServices";
import { useNavigate } from 'react-router-dom';

function Footer() {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate()

    const navegarComponente = (component: any) => {

        switch (component) {
            case "comite":
                navigate('/comite-remuneracion/')
                break;

            case "comisiones":
                navigate('/costos-comisiones/')
                break;

            case "cobranza":
                navigate('/despacho-cobranza/')
                break;

            case "terminos-condiciones":
                navigate('/terminos-condiciones/')
                break;

            case "buro":
                navigate('/new-buro/')
                break;

            case "privacidad":
                navigate('/aviso-privacidad/')
                break;

            default:
                break;
        }

    }

    /*useEffect(() => {
        obtenerComponentes()
    }, [])*/


    return (
        <Grid className="Footer_Grid" >
            <Container maxWidth={'xl'} >
                <Grid id="pie" container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0} /* sx={{pl:{md:0,lg:4}}} */>
                    <Grid item lg={3} sm={4} xs={12} sx={{ mt: { lg: 8, md: 5, sm: 5 }, textAlign: 'center' }}>
                        <h3 className="Footer_titles">Oficinas del Corporativo</h3>
                        <LocationOnIcon sx={{ color: 'red' }} fontSize='large' />
                        <div>

                            <a href="https://goo.gl/maps/a9DeoaMTUcsatVEW9" target={'_blank'} rel="noopener noreferrer" className={xs || sm ? 'Footer_a_small' : "Footer_a"}>
                                <Typography variant={xs || sm ? "subtitle1" : 'subtitle1'}  >Paseo de los insurgentes #301  <br /> Colonia los Paraísos, C.P 37328  <br /> León, Guanajuato.</Typography>
                            </a>
                        </div>
                        <p className={xs || sm ? 'Footer_p_small' : "Footer_p"}> <LocalPhoneIcon fontSize='small' sx={{ mr: 1 }} />(477) 461 47 00</p>
                        <p className={xs || sm ? 'Footer_p_small' : "Footer_p"}> <AccessTimeIcon fontSize='small' sx={{ mr: 1 }} /> Lun a Vie 08:00 a 18:00 hrs</p>
                    </Grid>
                    <Grid item lg={3} sm={4} xs={12} sx={{ mt: { lg: 8, md: 5, sm: 5 }, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                        <h3 className="Footer_titles">Trasparencia</h3>
                        <Typography sx={{ mb: 1 }}>
                            <Link color="inherit" href="/une/" underline="none" >UNE</Link>
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            <Link color="inherit" href="/fondo-de-proteccion/" underline="none"  >
                                Fondo de Protección
                            </Link>
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            <Link color="inherit" href="/reportes-financieros/" underline="none" >
                                Reportes Financieros
                            </Link>
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            <Link color="inherit" href="/aviso-legal/" underline="none" >
                                Aviso Legal
                            </Link>
                        </Typography>
                        <a onClick={() => navegarComponente("privacidad")} rel="noopener noreferrer" className="Footer_a">
                            Aviso de Privacidad.</a>

                        <a onClick={() => navegarComponente("comite")} rel="noopener noreferrer" className="Footer_a">
                            Comité de remuneración.</a>
                    </Grid>
                    <Grid item lg={3} sm={4} xs={12} sx={{ mt: { lg: 8, md: 5, sm: 5 }, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                        <h3 className="Footer_titles">Redes Sociales</h3>
                        <a href="https://www.facebook.com/avantthyaoficial/" target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                            <FacebookIcon sx={{ mr: 1 }} />   Facebook</a>
                        <a href="https://twitter.com/avantthya" target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                            <TwitterIcon sx={{ mr: 1 }} />  Twitter</a>
                        <a href="https://mx.linkedin.com/company/avantthya" target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                            <LinkedInIcon sx={{ mr: 1 }} /> Linkedin</a>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ pt: { sm: 0, md: 3 } }}
                        >
                            <Stack>
                                <a href="https://avantthya.com.mx/">
                                    <img style={{ width: 220, maxHeight: 200, }}
                                        src={`${logo_avantthya}`}
                                        alt="logo de avantthya"
                                        loading="lazy" />
                                </a>
                            </Stack>
                            <a onClick={() => navegarComponente("comisiones")} target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                                Consulta los costos y las comisiones <br />de nuestros productos.</a>
                            <a onClick={() => navegarComponente("terminos-condiciones")} target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                                Términos y condiciones <br />de nuestros productos.</a>
                            <a onClick={() => navegarComponente("cobranza")} target={'_blank'} rel="noopener noreferrer" className="Footer_a">
                                Despachos de cobranza.</a>
                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: '#e5e7e6', borderRadius: '12px', m: 0, pt: 0.5, pb: 0, pl: 2, pr: 1, mt: 1 }} >
                                <a onClick={() => navegarComponente("buro")} target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: 32, maxHeight: 32, marginRight: 4, cursor: 'pointer' }}
                                        src={`${buro}`}
                                        loading="lazy"
                                        alt="entidades regulatorias" />
                                </a>
                                <a href="https://www.gob.mx/condusef" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: 50, maxHeight: 50, marginRight: 3 }}
                                        src={`${condusef}`}
                                        loading="lazy"
                                        alt="entidades regulatorias" />
                                </a>
                                <a href="https://www.gob.mx/cnbv" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: 60, maxHeight: 60, }}
                                        src={`${cnbv}`}
                                        loading="lazy"
                                        alt="entidades regulatorias" />
                                </a>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item lg={12} xs={12} sm={12} md={12}
                        sx={{ textAlign: 'center', alignItems: 'center', justifyContent: "center", mt: 2, p: 0, pb: 2, display: 'flex', mb: 0 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems={xs || sm ? 'center' : "flex-start"}
                        >
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <h3>OPERACIONES DE TU LADO S.A. DE C.V., S.F.P.</h3>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CopyrightIcon sx={{ mr: 1 }} /> Derechos reservados {new Date().getFullYear()}</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}
export default Footer;