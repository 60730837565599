export interface DataStatusStructure {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error: any;
    enabled: boolean;
}

export interface QuestionStructure {
    id: number;
    question: string | null;
    description: string | null;
    type: string;
    status: boolean;
}

export interface AgreementStructure {
    id: number
    name: string;
    completeName: string
    status: boolean
    image: string
    description: string
}

export const initialDataStatus: DataStatusStructure = {
    isLoading: true,
    isError: false,
    data: [],
    error: null,
    enabled: true,
};

export const initialAgreement: AgreementStructure = {
    id: 0,
    name: "",
    completeName: "",
    status: true,
    image: "",
    description: ""
}

export interface AgreementLeadStructure {
    id: number;
    name: string;
    phone?: string;
    email?: string;
    amountString: string;
    idstatus?: number;
    agreementid?: number;
    metafields?: String;
    productName: String | null;
}

export const initialAgreementLead = {
    id: 1,
    name: "",
    phone: "",
    email: "",
    amountString: "",
    idstatus: 0,
    agreementid: 0,
    metafields: "",
    productName: ""
}

export const baseFields = [
    { key: "name", label: "Nombre:", helperText: "Escribe tu Nombre", type: "text"},
    { key: "phone", label: "Teléfono", helperText: "Escribe tu Teléfono", type: "tel"},
    { key: "email", label: "Email:", helperText: "Escribe tu Email", type: "email"},
    { key: "amountString", label: "Cantidad solicitada:", helperText: "Escribe la cantidad solicitada", type: "email"},
];