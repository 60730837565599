import React, { forwardRef, ReactElement, Ref } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Slide, SlideProps } from "@mui/material";

interface MessageDialogProps {
    open: boolean;
    dialogTitle: string;
    dialogMessage: string;
    onCloseFunction?: () => void;
    buttonMessage: string;
}

const Transition = forwardRef(function Transition(
    props: SlideProps,
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const MessageDialog: React.FC<MessageDialogProps> = ({ open, dialogTitle, dialogMessage, onCloseFunction, buttonMessage }) => {
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted disableEscapeKeyDown>
            <DialogTitle align="center">{dialogTitle}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseFunction} variant="contained" type="submit">
                    {buttonMessage}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
