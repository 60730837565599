import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ComponentRender() {

    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <Container sx={{ height: '100%' }}>
            <div style={{ height: 900, margin: 2 }}>
                <embed src={location.state.image} width={'100%'} height={'100%'}>
                </embed>
            </div>
        </Container >
    )
}
