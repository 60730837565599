import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField, Toolbar,
    Typography
} from "@mui/material";
import SkeletonBlog from "../../components/pages/SkeletonBlog";
import ParagraphText from "../../components/pages/ParagraphText";
import {
    AgreementLeadStructure,
    AgreementStructure, baseFields,
    DataStatusStructure, initialAgreement, initialAgreementLead,
    initialDataStatus, QuestionStructure,
} from "./AgreementDataStructures";
import {useNavigate, useParams} from "react-router-dom";
import {
    getAgreementInfo,
    getAllQuestionsByAgreement, getProductsNameInLeads,
    SaveNewAgreementLead
} from "../../services/QuestionAgreementsServices";
import {useMutation, useQuery} from "react-query";
import {MessageDialog} from "../../components/general/MessageDialog";

const AgreementForm = () => {
    const navigate = useNavigate();
    const [questionsData, setQuestionsData] = useState<DataStatusStructure>(initialDataStatus)
    const [agreementData, setAgreementData] = useState<AgreementStructure>(initialAgreement)
    const [lead, setLead] = useState<AgreementLeadStructure>(initialAgreementLead)
    const {agreementId, agreement} = useParams();
    const [metaFields, setMetaFields] = useState<{ name: string; values: any }[]>([]);
    const [productList, setProductList] = useState<number[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [dialogMessage, setDialogMessage] = useState<string>("");


    useQuery(["getAgreementQuestions"], () => {
        const id = typeof agreementId === 'string' ? parseInt(agreementId) : undefined;
        return id !== undefined ? getAllQuestionsByAgreement(id) : undefined;
    }, {
        enabled: questionsData.enabled,
        // @ts-ignore
        onSuccess: ({data}) => {
            setQuestionsData(prevState => ({
                ...prevState,
                data: data,
                enabled: false,
                isError: false,
                isLoading: false
            }));
            // @ts-ignore
            setLead(prevState => ({...prevState, agreementid: parseInt(agreementId)}))
        },
        onError: (error: any) => setQuestionsData(prevState => ({
            ...prevState,
            error: error.response,
            isError: true
        })),
    });

    useQuery(["getAgreementInfo"], () => {
        const id = typeof agreementId === 'string' ? parseInt(agreementId) : undefined;
        return id !== undefined ? getAgreementInfo(id) : undefined;
    }, {
        enabled: questionsData.enabled,
        // @ts-ignore
        onSuccess: ({data}) => {
            setAgreementData(prevState => ({
                ...prevState, id: data.agreementId,
                name: data.agreementName,
                completeName: data.agreementCompleteName,
                status: data.status,
                image: data.agreementImg,
                description: data.agreementDescription
            }))
        },
        onError: (error: any) => setQuestionsData(prevState => ({
            ...prevState,
            error: error.response,
            isError: true
        })),
    });

    useQuery(["getProductNameLeadTable"], getProductsNameInLeads, {
        enabled: questionsData.enabled, onSuccess: (data) => {
            setProductList(data.data)
        }, onError: (error: any) => setQuestionsData(prevState => ({
            ...prevState,
            error: error.response,
            isError: true
        })),
    });

    function setLeadFields(key: string, value: any, type: string | null = null) {
        const isNumber = /^\d+$/.test(value);
        const isNotNumber = /\D/.test(value);

        if ((type === "number" && !isNumber) || (type === "tel" && !isNumber) || (type === "text" && !isNotNumber)) {
            return;
        } else {
            if (Object.prototype.hasOwnProperty.call(lead, key)) {
                setLead(prevState => ({...prevState, [key]: value}));
            } else {
                const index = metaFields.findIndex(obj => obj.name === key);
                if (index !== -1) {
                    const updatedMetaFields = [...metaFields];
                    updatedMetaFields[index].values = [value];
                    setMetaFields(updatedMetaFields);
                    setLead(prevState => ({
                        ...prevState,
                        metafields: JSON.stringify(updatedMetaFields)
                    }));
                } else {
                    const newMetaFields = [...metaFields, {name: key, values: [value]}];
                    setMetaFields(newMetaFields);
                    setLead(prevState => ({
                        ...prevState,
                        metafields: JSON.stringify(newMetaFields)
                    }));
                }
            }
        }

    }

    const saveLead = useMutation(["UpdateQuestions"], () => SaveNewAgreementLead(lead), {
        onSuccess: () => {
            setLead(initialAgreementLead)
            setDialogMessage("Pronto uno de nuestros asesores se pondrá en contacto con usted.")
            setDialogTitle("Guardado con Éxito.")
            setOpen(true)
        },
        onError: (error: any) => {
            setDialogMessage("A ocurrido un error, por favor intentalo mas tarde y revisa bien su información e instrucciones")
            setDialogTitle("Error")
            setOpen(true)
        }

    })

    return (
        <Grid
            container
            direction="row"
            sx={{display: 'flex', mb: 5, bgcolor: '#23398F', color: "whitesmoke"}}
        >
            {questionsData.isLoading || (agreement != agreementData.name) ? (
                <SkeletonBlog/>
            ) : (
                <Grid
                    container
                    direction="row"
                    spacing={4}
                    sx={{
                        display: 'flex',
                        bgcolor: '#001933',
                        color: 'white',
                        textAlign: 'justify',
                        p: {md: 2, sm: 2, xs: 2},
                    }}
                >
                    {
                        agreementData.status
                            ? <>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {agreementData.image && (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-end"
                                            sx={{
                                                p: 2,
                                                m: 2,
                                            }}
                                        >
                                            <img
                                                className='blog-img-b'
                                                src={`${agreementData.image}`}
                                                loading="lazy"
                                                alt={'portada'}
                                                aria-hidden="true"
                                                style={{
                                                    width: "70%",
                                                    height: "auto",
                                                    maxHeight: "400px"
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems={"center"}
                                        alignContent={"center"}
                                    >
                                        <br/>
                                        <Typography variant={"h4"} className='blog-title-b'>
                                            {agreementData.name}
                                        </Typography>
                                        <ParagraphText text={agreementData.completeName} intro={true}/>
                                        <Toolbar/>
                                        <Typography variant={"h6"}>{agreementData.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Stack direction="column"
                                           sx={{
                                               backgroundColor: "whitesmoke",
                                               border: "2px solid black",
                                               p: 3,
                                               m: 3,
                                               borderRadius: 5
                                           }}>
                                        <Typography variant={"h4"} color={"black"}>Completa correctamente el
                                            formulario</Typography>
                                        {baseFields.map((question) => {
                                            return (
                                                <TextField
                                                    key={question.key}
                                                    margin={"normal"}
                                                    helperText={question.helperText}
                                                    label={question.label}
                                                    // placeholder={question.label}
                                                    variant={"outlined"}
                                                    // @ts-ignore
                                                    value={lead[question.key]}
                                                    type={question.type}
                                                    onChange={(e) => setLeadFields(question.key, e.target.value, question.type)}
                                                />
                                            )

                                        })}
                                        {questionsData.data.map(({question}: QuestionStructure, index: number) => {
                                            // @ts-ignore
                                            if (question.status && questionsData.data[index].status)
                                                return (
                                                    // @ts-ignore
                                                    <TextField
                                                        // @ts-ignore
                                                        key={question.id} z
                                                        margin='normal'
                                                        // @ts-ignore
                                                        helperText={question.description ?? ''}
                                                        // @ts-ignore
                                                        id={question.id.toString()}
                                                        // @ts-ignore
                                                        label={question.question ?? ''}
                                                        // @ts-ignore
                                                        required
                                                        // @ts-ignore
                                                        onChange={(e) => setLeadFields(question.question, e.target.value)}
                                                        variant={"outlined"}/>
                                                );
                                        })}
                                        <FormControl fullWidth>
                                            <InputLabel id="productName-select-label"
                                                        sx={{m: 0, pt: 0}}>Producto</InputLabel>
                                            <Select
                                                sx={{mt: 1}}
                                                size='small'
                                                labelId="productName-select-label"
                                                id="productName"
                                                name="productName"
                                                label="Producto"
                                                value={lead.productName}
                                                // @ts-ignore
                                                onChange={(e) => setLeadFields("productName", e.target.value)}
                                            >{productList.length > 0 ? productList.map((item: any, idx: number) => {
                                                return (<MenuItem key={idx}
                                                                  value={item == null ? "" : item}> {item == null ? "Selecciona un producto" : item} </MenuItem>);
                                            }) : <MenuItem>Sin opciones</MenuItem>}
                                            </Select>
                                        </FormControl>
                                        <Button variant={"contained"} onClick={() => saveLead.mutate()}
                                                sx={{m: 4}}>Enviar</Button>
                                    </Stack>
                                </Grid>
                            </>
                            : <SkeletonBlog/>
                    }
                </Grid>
            )}
            <MessageDialog open={open} dialogTitle={dialogTitle}
                           dialogMessage={dialogMessage}
                           buttonMessage={"Aceptar"} onCloseFunction={() => navigate("/")}/>
        </Grid>

    );
};

export default AgreementForm;